import React, { useContext, useEffect } from 'react'
import { Functionality } from '../context/Functionality.js'
import StudentCount from './StudentCards';
import StudentListTable from './StudentTable.js';

export default function EmployeeStudentList() {

    const { students, getAllStudents } = useContext(Functionality)

    useEffect(() => {
        getAllStudents()
    }, [])


    const unContactedNum = (students?.students?.filter(student => student.uncontacted) || []).length;
    const processingNum = (students?.students?.filter(student => student.processing) || []).length;
    const acceptedNum = (students?.students?.filter(student => student.accepted) || []).length;
    const enrolledNum = (students?.students?.filter(student => student.enrolled) || []).length;
    const declinedNum = (students?.students?.filter(student => student.declined) || []).length;


    return (
        <div>
            <StudentCount unContactedNum={unContactedNum} processingNum={processingNum} enrolledNum={enrolledNum} acceptedNum={acceptedNum} declinedNum={declinedNum} />

            <div className="employeePortal-studentList mt-8">
                <StudentListTable />
            </div>
        </div>
    )
}