import React, { useState, useContext } from 'react';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import LoginImage from '../../outside/email.png';
import { Link } from 'react-router-dom';
import { Functionality } from '../../context/Functionality';

export default function Register() {
    const { handleRegister } = useContext(Functionality)

    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: ''
    });


    const [errors, setErrors] = useState({});

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            handleRegister(formData);
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.firstName.trim()) {
            newErrors.firstName = 'First name is required';
        }
        if (!formData.lastName.trim()) {
            newErrors.lastName = 'Last name is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else if (formData.password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
        }

        return newErrors;
    };

    return (
        <>
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div >
            <section className="min-h-screen flex items-center justify-center">
                {/* Register container */}
                <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-5xl p-5 items-center">
                    {/* Image */}
                    <div className="md:block hidden w-1/2">
                        <img
                            className="rounded-2xl"
                            src={LoginImage}
                            alt="Register visual"
                        />
                    </div>

                    {/* Form */}
                    <div className="md:w-1/2 px-8 md:px-16">
                        <h2 className="font-bold text-2xl text-[#002D74]">Create Account</h2>

                        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                            <input
                                className={`p-2 mt-8 rounded-xl border ${errors.firstName ? 'border-red-500' : ''}`}
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                placeholder="Enter first name"
                            />
                            {errors.firstName && <p className="text-red-500 text-xs">{errors.firstName}</p>}

                            <input
                                className={`p-2 rounded-xl border ${errors.lastName ? 'border-red-500' : ''}`}
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                placeholder="Enter last name"
                            />
                            {errors.lastName && <p className="text-red-500 text-xs">{errors.lastName}</p>}

                            <input
                                className={`p-2 rounded-xl border ${errors.email ? 'border-red-500' : ''}`}
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter email"
                            />
                            {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}

                            <div className="relative">
                                <input
                                    className={`p-2 rounded-xl border w-full ${errors.password ? 'border-red-500' : ''}`}
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    placeholder="Enter password"
                                />
                                {showPassword ? (
                                    <FaRegEyeSlash
                                        className="bi bi-eye-slash absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                                        onClick={togglePasswordVisibility}
                                    />
                                ) : (
                                    <FaRegEye
                                        className="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                                        onClick={togglePasswordVisibility}
                                    />
                                )}
                            </div>
                            {errors.password && <p className="text-red-500 text-xs">{errors.password}</p>}

                            <button className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300">
                                Sign Up
                            </button>
                        </form>

                        <div className="mt-6 grid items-center text-gray-400">
                            <hr className="divide-y" />
                        </div>

                        <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]">
                            <p>Already have an account?</p>
                            <button className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300">
                                <Link to='/login'>Login</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
