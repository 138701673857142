import React, { useContext, useState } from 'react';
import { FaRegEye } from "react-icons/fa6";
import LoginImage from '../../outside/logistics.png';
import { Functionality } from '../../context/Functionality';
import { FcGoogle } from "react-icons/fc";
import { FaRegEyeSlash } from "react-icons/fa6";
import { Link } from 'react-router-dom';

export default function Login() {
    const { auth, handleChangeAuth, handleAuthSubmit, token, logout } = useContext(Functionality)
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <>
            {/* animation effect start */}
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            {/* animation effect end */}
            <section className=" min-h-screen flex items-center justify-center ">
                {/* login container */}
                <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-5xl p-5 items-center ">
                    {/* image */}
                    <div className="md:block hidden w-1/2">
                        <img
                            className="rounded-2xl"
                            src={LoginImage}
                            alt="Login visual"
                        />
                    </div>

                    {/* form */}
                    <div className="md:w-1/2 px-8 md:px-16">
                        <h2 className="font-bold text-2xl text-[#002D74]">Login</h2>
                        <p className="text-xs mt-4 text-[#002D74]">
                            If you are already a member, easily log in
                        </p>

                        <form action="" className="flex flex-col gap-4" onSubmit={token ? logout : handleAuthSubmit}>
                            <input
                                className="p-2 mt-5 rounded-xl border authForm-form-email"
                                type="email"
                                name="email"
                                value={auth.email}
                                onChange={handleChangeAuth}
                                placeholder="Email"
                            />
                            <div className="relative">
                                <input
                                    className="p-2 rounded-xl border w-full"
                                    type={showPassword ? "text" : "password"} // Toggle input type
                                    name="password"
                                    value={auth.password}
                                    onChange={handleChangeAuth}
                                    placeholder="Password"
                                />
                                {/* Toggle icon */}
                                {showPassword ? (
                                    <FaRegEyeSlash
                                        className="bi bi-eye-slash absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                                        onClick={togglePasswordVisibility}
                                    />
                                ) : (
                                    <FaRegEye
                                        className="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                                        onClick={togglePasswordVisibility}
                                    />
                                )}

                            </div>
                            <button className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300">
                                Login
                            </button>
                        </form>

                        <div className="mt-6 grid grid-cols-3 items-center text-gray-400">
                            <hr className="border-gray-400" />
                            <p className="text-center text-sm">OR</p>
                            <hr className="border-gray-400" />
                        </div>

                        <button className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]">
                            <FcGoogle className="mr-3 size-7" />
                            Login with Google
                        </button>

                        <div className="mt-5 text-xs border-b border-[#002D74] py-4 text-[#002D74]">
                            <a href="#">Forgot your password?</a>
                        </div>

                        <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]">
                            <p>Don't have an account?</p>
                            <button className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300">
                                <Link to='/register'>Register</Link>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
