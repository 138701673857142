import React, { useContext, useEffect, useState } from 'react'
import { Functionality } from '../context/Functionality.js'
import { useParams } from 'react-router-dom';
import Loading from './Loading.js';

export default function EmployeePost() {
    const { blogId } = useParams();
    const { getSingleBlogDetail, singleBlogDetail } = useContext(Functionality);

    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getSingleBlogDetail(blogId);
            setLoading(false);
        };

        fetchData();
    }, [blogId]);

    function formatReadableDate(dateString) {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <>
            <section
                class="pt-8 lg:pt-10 lg:pb-10 bg-[url('https://pagedone.io/asset/uploads/1691055810.png')] bg-center bg-cover"
            >
                <section class="py-8 md:py-16 ">
                    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div class="flex justify-between items-center flex-col lg:flex-row ">
                            <div class="w-full lg:w-1/2 md:pr-9">
                                <h4 className='text-17 text-custom-blue-darker font-bold lg:text-start text-center'>Written by: {singleBlogDetail?.author} </h4>
                                <span className='text-15 text-indigo-600  font-bold lg:text-start text-center block'> <span className='text-custom-blue-darker'>Published on:</span> {formatReadableDate(singleBlogDetail?.date)}</span>
                                <h2
                                    class="font-manrope text-3xl lg:text-5xl text-gray-900 font-bold leading-[2rem] lg:leading-[4rem] mb-5 mt-4 text-center lg:text-left">
                                    {singleBlogDetail?.title}</h2>
                                <h3 className=' text-20 lg:text-2xl text-gray-900 font-bold leading-[2rem] mb-7 text-center lg:text-left'> {singleBlogDetail?.subtitle}</h3>
                                <p class="lg:text-lg text-17 text-gray-500 md:mb-16 text-center lg:text-left "> {singleBlogDetail?.content}</p>
                            </div>
                            <div class="w-full lg:w-1/2 max-lg:max-w-2xl">
                                <img src={singleBlogDetail?.img1} alt='img1'
                                    class={`${singleBlogDetail?.img3 ? 'md:h-96' : 'md:h-64'} w-full h-auto  rounded-2xl object-cover`} />
                                <div class={`grid grid-cols-1 ${singleBlogDetail?.img3 ? 'md:grid-cols-2' : 'md:grid-cols-1'}  gap-4 mt-3`}>
                                    <img src={singleBlogDetail?.img2} alt="img2"
                                        class={`w-full rounded-2xl object-cover  h-auto ${singleBlogDetail?.img3 ? 'md:h-52' : 'md:h-64'} `} />
                                    {singleBlogDetail?.img3 &&
                                        <img src={singleBlogDetail?.img3} alt="img3"
                                            class=" w-full rounded-2xl object-cover mx-auto h-auto md:h-52 " />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>



        </>
    )
}