import React, { useState, useContext, useEffect } from 'react';
import { Functionality } from '../context/Functionality.js';

export default function AddBlog(props) {
    const { toggleModal, selectedBlog } = props;
    const { blogInputs, handleBlogChange, handleBlogSubmit } = useContext(Functionality);

    // Form data state
    const [formData, setFormData] = useState({
        title: '',
        subtitle: '',
        author: '',
        date: '',
        content: '',
        img1: '',
        img2: '',
        img3: '',
        metaTitle: '',
        metaDescription: '',
    });

    // Error state for validations
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (selectedBlog) {
            setFormData({
                title: selectedBlog.title || '',
                subtitle: selectedBlog.subtitle || '',
                author: selectedBlog.author || '',
                date: selectedBlog.date || '',
                content: selectedBlog.content || '',
                img1: selectedBlog.img1 || '',
                img2: selectedBlog.img2 || '',
                img3: selectedBlog.img3 || '',
                metaTitle: selectedBlog.metaTitle || '',
                metaDescription: selectedBlog.metaDescription || '',
            });
        }
    }, [selectedBlog]);

    // Input change handler
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // URL validation helper
    const isValidURL = (url) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    };

    // Form validation function
    const validateForm = () => {
        const newErrors = {};

        if (!formData.title || formData.title.length < 10) {
            newErrors.title = 'Title is required and should be at least 10 characters long.';
        }
        if (!formData.subtitle || formData.subtitle.length < 5) {
            newErrors.subtitle = 'Subtitle is required and should be at least 5 characters long.';
        }
        if (!formData.author || formData.author.length < 3) {
            newErrors.author = 'Author is required and should be at least 3 characters long.';
        }
        if (!formData.date) {
            newErrors.date = 'Date is required.';
        }
        if (!formData.content || formData.content.length < 50) {
            newErrors.content = 'Content is required and should be at least 50 characters long.';
        }
        if (!formData.metaTitle || formData.metaTitle.length < 10) {
            newErrors.metaTitle = 'Meta Title is required and should be at least 10 characters long.';
        }
        if (!formData.metaDescription || formData.metaDescription.length < 50) {
            newErrors.metaDescription = 'Meta Description is required and should be at least 50 characters long.';
        }
        if (formData.img1 && !isValidURL(formData.img1)) {
            newErrors.img1 = 'Please enter a valid URL for Image 1.';
        }
        if (formData.img2 && !isValidURL(formData.img2)) {
            newErrors.img2 = 'Please enter a valid URL for Image 2.';
        }
        if (formData.img3 && !isValidURL(formData.img3)) {
            newErrors.img3 = 'Please enter a valid URL for Image 3.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Submit handler
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (selectedBlog) {
                toggleModal();
            } else {
                handleBlogSubmit(e);
            }
            toggleModal();
        }
    };

    return (
        <div>
            <div className="bg-white mx-auto mt-14 md:mt-0">
                <form id="survey-form" onSubmit={handleSubmit}>
                    <div className='block md:grid grid-cols-2 gap-2'>
                        <div>
                            <label className="block" htmlFor="title" id="title-label">
                                <span className="font-semibold text-gray-700 text-lg">Title</span>
                                <input
                                    className={`bg-gray-100 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded-md focus:border-blue-400 outline-none w-full block p-2 mt-2`}
                                    id="title"
                                    name='title'
                                    type="text"
                                    placeholder="Enter your title"
                                    value={blogInputs.title || formData.title}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleBlogChange(e);
                                    }}

                                />
                                {errors.title && <span className="text-red-500 text-sm">{errors.title}</span>}
                            </label>
                        </div>
                        <div>
                            <label htmlFor="subtitle" id="subtitle-label">
                                <span className="font-semibold text-gray-700 text-lg">Subtitle</span>
                                <input
                                    className={`bg-gray-100 border ${errors.subtitle ? 'border-red-500' : 'border-gray-300'} rounded-md focus:border-blue-400 outline-none w-full block p-2 mt-2`}
                                    id="subtitle"
                                    name='subtitle'
                                    type="text"
                                    placeholder="Enter subtitle"
                                    value={blogInputs.subtitle || formData.subtitle}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleBlogChange(e);
                                    }}

                                />
                                {errors.subtitle && <span className="text-red-500 text-sm">{errors.subtitle}</span>}
                            </label>
                        </div>
                    </div>
                    <div className="block md:grid grid-cols-2 gap-2">
                        <div className="w-full">
                            <label className="block my-3" htmlFor="author" id="author-label">
                                <span className="font-semibold text-gray-700 text-lg">Author</span>
                                <input
                                    className={`bg-gray-100 border ${errors.author ? 'border-red-500' : 'border-gray-300'} rounded-md focus:border-blue-400 outline-none w-full block p-2 mt-2`}
                                    id="author"
                                    name='author'
                                    type="text"
                                    placeholder="Enter author name"
                                    value={blogInputs.author || formData.author}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleBlogChange(e);
                                    }}

                                />
                                {errors.author && <span className="text-red-500 text-sm">{errors.author}</span>}
                            </label>
                        </div>

                        <div className="w-full">
                            <label htmlFor="date" className="block mt-3 text-lg font-semibold text-gray-700">Date</label>
                            <div className="relative">
                                <input
                                    id="date"
                                    name="date"
                                    type="date"
                                    className={`bg-gray-100 border ${errors.date ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full pl-3 mt-2 p-2`}
                                    value={blogInputs.date || formData.date}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleBlogChange(e);
                                    }}

                                />
                                {errors.date && <span className="text-red-500 text-sm">{errors.date}</span>}
                            </div>
                        </div>
                    </div>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4'>
                        <div>
                            <label htmlFor="img1" className="block my-3 text-lg font-semibold text-gray-700">Image 1 URL</label>
                            <input
                                id="img1"
                                type="url"
                                name='img1'
                                className={`bg-gray-100 border ${errors.img1 ? 'border-red-500' : 'border-gray-300'} rounded-md focus:border-blue-400 outline-none w-full block p-2 mt-2`}
                                placeholder="Enter URL for image 1"
                                value={blogInputs.img1 || formData.img1}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleBlogChange(e);
                                }}
                            />
                            {errors.img1 && <span className="text-red-500 text-sm">{errors.img1}</span>}
                        </div>
                        <div>
                            <label htmlFor="img2" className="block my-3 text-lg font-semibold text-gray-700">Image 2 URL</label>
                            <input
                                id="img2"
                                name='img2'
                                type="url"
                                className={`bg-gray-100 border ${errors.img2 ? 'border-red-500' : 'border-gray-300'} rounded-md focus:border-blue-400 outline-none w-full block p-2 mt-2`}
                                placeholder="Enter URL for image 2"
                                value={blogInputs.img2 || formData.img2}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleBlogChange(e);
                                }}
                            />
                            {errors.img2 && <span className="text-red-500 text-sm">{errors.img2}</span>}
                        </div>
                        <div>
                            <label htmlFor="img3" className="block my-3 text-lg font-semibold text-gray-700">Image 3 URL</label>
                            <input
                                id="img3"
                                name='img3'
                                type="url"
                                className={`bg-gray-100 border ${errors.img3 ? 'border-red-500' : 'border-gray-300'} rounded-md focus:border-blue-400 outline-none w-full block p-2 mt-2`}
                                placeholder="Enter URL for image 3"
                                value={blogInputs.img3 || formData.img3}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleBlogChange(e);
                                }}
                            />
                            {errors.img3 && <span className="text-red-500 text-sm">{errors.img3}</span>}
                        </div>
                    </div>

                    <label className="block" htmlFor="metaTitle" id="title-label">
                        <span className="block my-3 text-lg font-semibold text-gray-700">Meta Title</span>
                        <input
                            className={`bg-gray-100 border ${errors.metaTitle ? 'border-red-500' : 'border-gray-300'} rounded-md focus:border-blue-400 outline-none w-full block p-2 mt-2`}
                            id="metaTitle"
                            name='metaTitle'
                            type="text"
                            placeholder="Enter your meta title"
                            value={blogInputs.metaTitle || formData.metaTitle}
                            onChange={(e) => {
                                handleChange(e);
                                handleBlogChange(e);
                            }}
                        />
                        {errors.metaTitle && <span className="text-red-500 text-sm">{errors.metaTitle}</span>}
                    </label>
                    <div className="block md:grid grid-cols-2 gap-2">
                        <div>
                            <label htmlFor="content" className="block my-3 text-lg font-semibold text-gray-700">Content</label>
                            <textarea
                                id="content"
                                name='content'
                                rows="4"
                                className={`block p-2 w-full text-gray-700 bg-gray-100 border ${errors.content ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-blue-500 focus:border-blue-500`}
                                placeholder="Write your content here..."
                                value={blogInputs.content || formData.content}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleBlogChange(e);
                                }}

                            ></textarea>
                            {errors.content && <span className="text-red-500 text-sm">{errors.content}</span>}
                        </div>
                        <div>
                            <label htmlFor="metaDescription" className="block my-3 text-lg font-semibold text-gray-700">Meta Description</label>
                            <textarea
                                id="metaDescription"
                                name='metaDescription'
                                rows="4"
                                className={`block p-2 w-full text-gray-700 bg-gray-100 border ${errors.metaDescription ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-blue-500 focus:border-blue-500`}
                                placeholder="Write your meta-description here..."
                                value={blogInputs.metaDescription || formData.metaDescription}
                                onChange={(e) => {
                                    handleChange(e);
                                    handleBlogChange(e);
                                }}

                            ></textarea>
                            {errors.metaDescription && <span className="text-red-500 text-sm">{errors.metaDescription}</span>}
                        </div>
                    </div>
                    <div className="flex items-center justify-center my-5">
                        <button type="submit" className="ml-2 px-6 py-2.5 font-semibold text-xs  leading-normal text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-all w-full sm:w-auto">Submit Blog</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
