import React, { useContext, useState } from 'react';
import AddBlog from './AddBlog';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom'
import { Functionality } from '../context/Functionality';
import { Link } from 'react-router-dom'

export default function BlogTable() {

    const { blogs } = useContext(Functionality);
    const isMobile = useMediaQuery({ query: '(max-width: 650px)' });
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);

    const toggleModal = (blog) => {
        setIsModalOpen(!isModalOpen);
        setSelectedBlog(blog);
    };



    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 8;

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentBlogs = blogs?.blogs?.slice(indexOfFirstRow, indexOfLastRow);

    // Calculate pagination info
    const totalBlogs = blogs?.blogs?.length;
    const showingFrom = totalBlogs === 0 ? 0 : indexOfFirstRow + 1;
    const showingTo = Math.min(indexOfLastRow, totalBlogs);

    return (
        <div>
            <div className="overflow-x-auto">
                <table className="min-w-full border">
                    <thead>
                        <tr className="bg-blue-600 text-white">
                            <th scope="col" className="px-2 py-3 text-xs font-medium tracking-wider text-left uppercase border-b">
                                Title
                            </th>
                            <th scope="col" className="px-2 py-3 text-xs font-medium tracking-wider text-left uppercase border-b">
                                Subtitle
                            </th>
                            <th scope="col" className="px-2 py-3 text-xs font-medium tracking-wider text-left uppercase border-b">
                                Author
                            </th>
                            <th scope="col" className="px-2 py-3 text-xs font-medium tracking-wider text-left uppercase border-b">
                                Content
                            </th>
                            <th scope="col" className="px-2 py-3 text-xs font-medium tracking-wider text-left uppercase border-b">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {currentBlogs?.map((blog, index) => (
                            <tr key={index}>
                                <td data-label="Title" className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div className="text-sm font-medium leading-5 text-blue-500">
                                        <Link to={`/employee-post/${blog._id}`}
                                            target={'blank'}>
                                            {blog?.title}
                                        </Link>
                                    </div>
                                </td>
                                <td data-label="Sub Title" className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div className="text-sm leading-5 text-gray-900">{blog?.subtitle}</div>
                                </td>
                                <td data-label="Auther Name" className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div className="text-sm leading-5 text-gray-900">{blog?.author}</div>
                                </td>
                                <td data-label="Content" className="px-2 py-4 whitespace-no-wrap border-b border-gray-200">
                                    <div className="text-sm leading-5 text-gray-900">{blog?.content?.slice(0, 100)}...</div>
                                </td>
                                <td data-label="Action" className="px-2 py-4 whitespace-no-wrap ">
                                    <button
                                        className="text-indigo-600 hover:text-indigo-900"
                                        type="button"
                                        onClick={() => {
                                            isMobile ? navigate('/add-blog') : toggleModal(blog);
                                        }}
                                    >
                                        Edit
                                    </button>
                                    <Link
                                        className='pl-6'
                                        to={`/employee-post/${blog._id}`}
                                        target={'blank'}
                                    >
                                        <button
                                            className="text-indigo-600 hover:text-indigo-900"
                                            type="button"
                                        >
                                            View
                                        </button>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {blogs?.blogs?.length === 0 &&
                    <div className="flex flex-col items-center justify-center py-8">
                        <svg
                            className="w-12 h-12 text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 13h6m2 6H7a2 2 0 01-2-2V7a2 2 0 012-2h10a2 2 0 012 2v10a2 2 0 01-2 2z"
                            />
                        </svg>
                        <p className="mt-4 text-lg font-medium text-gray-600">
                            No Data Available
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                            There are currently no blogs to display.
                        </p>
                    </div>
                }
            </div>
            {/* Pagination Info */}
            {blogs?.blogs?.length !== 0 &&
                <div className="flex justify-between items-center p-2">
                    <p className="text-sm text-gray-700">
                        {`Showing ${showingFrom} to ${showingTo} of ${totalBlogs} results`}
                    </p>

                    {/* Pagination Controls */}
                    <div className="flex">
                        <button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg mx-2"
                        >
                            Prev
                        </button>
                        <button
                            onClick={() => setCurrentPage(prev => (indexOfLastRow < totalBlogs ? prev + 1 : prev))}
                            disabled={indexOfLastRow >= totalBlogs}
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                        >
                            Next
                        </button>
                    </div>
                </div>
            }
            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-50">
                    <div className="relative w-full max-w-lg sm:max-w-2xl lg:max-w-4xl p-6 ml-4 mr-4 mx-auto bg-white rounded-xl shadow-2xl">
                        {/* Header */}
                        <div className="flex items-center justify-betweenrounded-t dark:border-gray-600 mb-1">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Blog Details
                            </h3>
                            <button onClick={toggleModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <AddBlog selectedBlog={selectedBlog} toggleModal={toggleModal} />
                    </div>
                </div>
            )}
        </div>
    )
}
