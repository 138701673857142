import React, { useState, useEffect, useContext, useRef } from 'react'
import { Functionality } from '../context/Functionality.js'
import EmployeeHeader from './EmployeeHeader';
import Sidebar from './Sidebar';
import { FaBars, FaTimes } from 'react-icons/fa';

export default function EmployeePortal({ children }) {

    const { getAllStudents, getAllBusinesses } = useContext(Functionality)

    useEffect(() => {
        getAllStudents()
        getAllBusinesses()
    }, []);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);

    // Toggle the sidebar when the button is clicked
    const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };

    // Close sidebar on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside the sidebar
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsSidebarOpen(false);
            }
        };

        // Add event listener for click events
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sidebarRef]);

    return (
        <>
            <div className="flex flex-col md:flex-row h-screen overflow-x-hidden">
                {/* Toggle button for mobile view */}
                <div onClick={toggleSidebar}>
                    <span
                        className="toggle-button fixed z-10 left-5 top-5 text-blue  md:hidden cursor-pointer"
                    >
                        {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </span>
                </div>

                {/* Sidebar */}
                <div
                    ref={sidebarRef}
                    className={`fixed inset-0 z-30 transform transition-transform duration-300 bg-[#0e2234] w-60 h-full overflow-hidden sidebar md:relative md:translate-x-0 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                        }`}
                >
                    <Sidebar toggleSidebar={toggleSidebar} />
                </div>

                {/* Main Content Area */}
                <div className="flex-1" >
                    <div className="min-h-screen flex flex-col">
                        <EmployeeHeader />
                        <div className="flex-1 p-4">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}