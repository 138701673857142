import React, { useContext } from 'react';
import { Functionality } from '../context/Functionality';
import { Link } from 'react-router-dom';

const EmployeeHeader = ({ toggleSidebar }) => {
    const { logout } = useContext(Functionality)

    const [notificationOpen, setNotificationOpen] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    return (
        <header className="flex items-center justify-between px-6 py-4 bg-white shadow-md md:static fixed w-full">
            <div>
            </div>
            <div className="flex items-center justify-end">
                <div className="relative">

                    {notificationOpen && (
                        <>
                            <div
                                onClick={() => setNotificationOpen(false)}
                                className="fixed inset-0 z-10 w-full h-full"
                            ></div>
                            <div className="absolute right-0 z-10 mt-2 overflow-hidden bg-white rounded-lg shadow-xl w-80">
                                <a href="#" className="flex items-center px-4 py-3 -mx-2 text-gray-600 hover:text-white hover:bg-indigo-600">
                                    <img
                                        className="object-cover w-8 h-8 mx-1 rounded-full"
                                        src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                                        alt="avatar"
                                    />
                                    <p className="mx-2 text-sm">
                                        <span className="font-bold">Slick Net</span> started following you . 45m
                                    </p>
                                </a>
                                <a href="#" className="flex items-center px-4 py-3 -mx-2 text-gray-600 hover:text-white hover:bg-indigo-600">
                                    <img
                                        className="object-cover w-8 h-8 mx-1 rounded-full"
                                        src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                                        alt="avatar"
                                    />
                                    <p className="mx-2 text-sm">
                                        <span className="font-bold">Slick Net</span> started following you . 45m
                                    </p>
                                </a>
                                {/* Add more notification items as needed */}
                            </div>
                        </>
                    )}
                </div>
                <div className="relative">
                    <button
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                        className="relative block w-8 h-8 overflow-hidden rounded-full shadow focus:outline-none"
                    >
                        <img
                            className="object-cover w-full h-full"
                            src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=296&q=80"
                            alt="Your avatar"
                        />
                    </button>

                    {dropdownOpen && (
                        <>
                            <div
                                onClick={() => setDropdownOpen(false)}
                                className="fixed inset-0 z-10 w-full h-full"
                            ></div>
                            <div className="absolute right-0 z-10 w-48 mt-2 overflow-hidden bg-white rounded-md shadow-xl">
                                <Link to="#" onClick={logout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                                    Logout
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </header>
    );
};

export default EmployeeHeader;
