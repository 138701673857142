import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { BsFillRocketFill } from "react-icons/bs";
import { MdBusinessCenter } from "react-icons/md";
import { SiXdadevelopers } from "react-icons/si";
import background from '../outside/hero-bg.jpg';
const Business = () => {


  const isMobile = useMediaQuery({ query: '(max-width: 320px)' });

  const sectionStyle = isMobile
      ? {}
      : {
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
      };

  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const questions = [
    {
      question: "How can I get started with BlakeTechnologies?",
      answer:
        "Getting started with BlakeTechnologies is easy! Sign up for an account, and you'll gain immediate access to our platform's features. No credit card is required for the initial signup.",
    },
    {
      question: "What is BlakeTechnologies' pricing structure?",
      answer:
        "BlakeTechnologies offers a flexible pricing structure. We provide both free and premium plans, allowing you to choose the one that fits your needs and budget.",
    },
    {
      question: "What kind of support does BlakeTechnologies provide?",
      answer:
        "BlakeTechnologies offers comprehensive customer support. You can reach out to our team via email, live chat, or consult our knowledge base for guidance.",
    },
    {
      question: "Can I cancel my BlakeTechnologies subscription anytime?",
      answer:
        "Yes, you can cancel your BlakeTechnologies subscription at any time without incurring any hidden fees. We aim to offer a hassle-free experience for all our users.",
    },
  ];

  const ourProjects = [
    {
      title: "Obstacle Avoidance",
      description: "Immerse yourself in a thrilling Vision Pro game where quick reflexes and...",
      imgUrl: "/images/Obstacle-Avoidance.jpg",
      link: "https://apps.apple.com/us/app/obstacle-avoidance/id6670704067"
    },
    {
      title: "Influencer Social",
      description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore...",
      imgUrl: "/images/influnce.webp",
      link: "https://apps.apple.com/us/app/influencer-social/id6557050600"
    },
    {
      title: "Chopp - Recipes by Chefs",
      description: "iCookOff is the new social media and playground for food lovers! This app...",
      imgUrl: "/images/choop.jpg",
      link: "https://apps.apple.com/us/app/chopp-recipes-by-chefs/id1481778343"
    },
    {
      title: "Skinni",
      description: "Tell Siri to add your calories for you. Hands free calorie counting using our...",
      imgUrl: "/images/hour.jpg",
      link: "https://apps.apple.com/us/app/skinni/id6444374624"
    }
  ];



  return (
    <>

      <main className="flex flex-1 w-full flex-col items-center justify-center text-center px-4 sm:py-28 py-10"
        style={sectionStyle}
      >
        <h1 className="mx-auto max-w-4xl font-roboto  font-bold text-24 lg:text-50 leading-36 lg:leading-60 text-white">Reach  <span className='text-[#fff]'>  New Heights </span>  Through Tech
          <span className="relative whitespace-nowrap text-white">
            <br className='lg:block' />
            <span className="relative">for Your Business</span>
          </span>
        </h1>

        <p className="mx-auto mt-8 font-roboto font-normal max-w-4xl text-lg text-white leading-7">Blake Technologies specializes in creating custom mobile app solutions for businesses looking to grow. We understand that building an app can be a huge daunting feat. We offer full service high-quality mobile apps developed by professionals.</p>

        <Link className=""
          to="/contact">
          <button className="relative w-full md:w-80 block md:inline-flex mt-6 items-center justify-center p-0.5 lg:mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
            <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              Get Started with Blake Technologies →
            </span>
          </button>
        </Link>
      </main>
      <div class="py-14 font-[sans-serif]">
        <div class="max-w-6xl max-lg:max-w-3xl max-sm:max-w-xl mx-auto">
          <div class="max-w-md mx-auto">
            <h2 class=" text-center font-roboto text-3xl lg:text-5xl font-bold tracking-normal text-slate-900 pb-9">Current <span className='text-[#1e62ec]'>Projects</span></h2>
          </div>
          <div className="grid grid-cols-1 px-4 sm:px-7 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 max-sm:gap-8">
            {ourProjects.map((project, index) => (
              <Link to={project.link} target="blank" rel="noopener noreferrer" key={index} className="bg-white rounded overflow-hidden">
                <img src={project.imgUrl} alt={`Blog Post ${index + 1}`} className="w-full h-60 object-cover" />
                <div className="pt-5">
                  <h3 className="text-lg font-bold text-gray-800 mb-3">{project.title}</h3>
                  <p className="text-gray-500 text-sm">{project.description}</p>
                  <Link to={project.link} target="blank">
                    <button className="relative w-full  block md:inline-flex mt-6 items-center justify-center p-0.5 lg:mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                      <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                        Read More
                      </span>
                    </button>
                  </Link>
                </div>
              </Link>
            ))}
          </div>

        </div>
      </div>

      <section className="bg-zinc-100 dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-8 lg:mb-16 text-center">
            <h2 className="mb-4 text-24 lg:text-50 leading-36 lg:leading-60 font-roboto  font-bold text-gray-900 dark:text-white"> <span className='text-[#1e62ec]'> Tailored Solutions  </span> for Business Growth and Improved Customer <span className='text-[#1e62ec]'> Experience</span> </h2>

            <p className="mx-auto mt-7 font-roboto max-w-xl text-lg text-white leading-7">At Blake Technologies, we fulfill two key needs:</p>
            <p className="mx-auto mt-3  font-roboto text-lg text-white leading-7">1. <strong>Businesses</strong> in need of elegant, custom mobile apps to support their growth.</p>
            <p className="mx-auto mt-1 font-roboto lg:pl-2 text-lg text-white leading-7">2. <strong>Customer Retention</strong> providing valuable, and memorable digital experiences.</p>

          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div className='bg-slate-50 hover:bg-slate-200 px-4 py-4 rounded-md'>
              <div className="flex flex-col items-center mb-4">
                <div className="w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900 flex items-center justify-center mb-4 mt-4">
                  <MdBusinessCenter className="w-12 h-12 text-primary-600 lg:w-12 lg:h-12 dark:text-primary-300" />
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-slate-700 text-center font-roboto ">Small Businesses</h3>
                <p className="text-gray-500 dark:text-gray-400 text-center font-roboto font-medium ">
                  Affordable mobile apps tailored to your business needs, developed by a team of professionals, ensuring top-quality results.
                </p>
              </div>
            </div>
            <div className='bg-slate-50 hover:bg-slate-200 px-4 py-4 rounded-md'>
              <div className="flex flex-col items-center text-center mb-4">
                <div className="w-10 h-10 lg:h-12 lg:w-12 rounded-full bg-primary-100 dark:bg-primary-900 flex items-center justify-center mb-4 mt-4">
                <SiXdadevelopers className="w-12 h-12 text-primary-600 lg:w-12 lg:h-12 dark:text-primary-300"/>
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-slate-700 text-center font-roboto">Large Businesses</h3>
                <p className="text-gray-500 dark:text-gray-400 text-center font-roboto font-medium">
                  Scalable tech that can support millions of users while maintaining high quality standards.
                </p>
              </div>
            </div>

            <div className='bg-slate-50 hover:bg-slate-200 px-4 py-4 rounded-md'>
              <div className="flex flex-col items-center text-center">
                <div className="w-10 h-10 lg:h-12 lg:w-12 rounded-full bg-primary-100 dark:bg-primary-900 flex items-center justify-center mb-4 mt-4">
                  <BsFillRocketFill className="w-12 h-12 text-primary-600 lg:w-12 lg:h-12 dark:text-primary-300" />
                </div>
                <h3 className="mb-2 text-xl font-bold dark:text-slate-700 text-center font-roboto">Startups</h3>
                <p className="text-gray-500 dark:text-gray-400 text-center font-roboto font-medium">
                  Launch your app idea quickly and cost-effectively with our flexible, scalable development solutions that grow with your business.
                </p>
              </div>
            </div>

          </div>
        </div>
      </section>





      <section className="py-10 bg-blue-50 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="font-roboto font-bold  text-black text-24 lg:text-50 leading-36 lg:leading-60">
              How <span className='text-[#1e62ec]'>  does it </span> work?
            </h2>
            <p className="mx-auto mt-7  font-roboto max-w-xl text-lg text-slate-700 leading-7">We’ve made the process of getting started with Blake Technologies simple.</p>
          </div>
          <div className="mx-auto mt-14 flex flex-col-reverse lg:grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4  sm:px-6  lg:max-w-7xl lg:grid-cols-2 lg:px-8">
            <div>
              {/* <h2 className=" text-2xl font-bold  font-roboto tracking-tight text-gray-900 sm:text-3xl">For  <span className='text-[#1e62ec]'>  Businesses </span></h2> */}
              <dl className="mt- pl-5">
                <ul className="list-disc">
                  <li className="font-medium text-gray-900 mt-3  font-roboto ">
                    <strong>Submit Your Inquiry:</strong> Fill out our contact form, and we’ll schedule a consultation to understand your app requirements.
                  </li>
                  <li className="font-medium text-gray-900 mt-3  font-roboto">
                    <strong>Define Your Solution:</strong> Work with our team to outline the app features that best meet your business needs.
                  </li>
                  <li className="font-medium text-gray-900 mt-3  font-roboto">
                    <strong>App Design:</strong> We will collaborate on a design for the app and agree on how the app will work / look for it's first version.
                  </li>
                </ul>
              </dl>
            </div>
            <div className="lg:pl-5">
              {/* <h2 className="text-3xl font-bold  font-roboto tracking-tight text-gray-900 sm:text-3xl">For Aspiring <span className='text-[#1e62ec]'>  Developers </span> </h2> */}
              <dl className="mt- pl-5">
                <ul className="list-disc">
                <li className="font-medium text-gray-900 mt-3  font-roboto">
                  <strong>App Development:</strong> A team of professional engineers will build your app, ensuring a high-quality product. while 
                  </li>
                  <li className="font-medium text-gray-900 mt-3  font-roboto">
                    <strong>Launch and Maintain:</strong> Once the initial version of the App is ready our team will submit it for Apple review. Once approved we will launch it onto the App Store.
                  </li>
                  <li className="font-medium text-gray-900 mt-3  font-roboto">
                    <strong>App Improvements:</strong> If new features / capabilities need to be added our team can plan and develop as needed.
                  </li>
                </ul>
              </dl>
            </div>
          </div>
        </div>
      </section>
      <section className="lg:py-24 py-7 bg-white lg:bg-blue-200 md:mx-0 mx-4">
        <div className="max-w-2xl mx-auto text-center">
          <h3 className=" font-bold  font-roboto  text-black text-24 lg:text-50 leading-36 lg:leading-60">
            Frequently  <span className='text-[#1e62ec]'>  Asked </span>  Questions
          </h3>
          <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16 mb-10">
            {questions.map((item, index) => (
              <div
                key={index}
                className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50"
              >
                <button
                  type="button"
                  onClick={() => toggleQuestion(index)}
                  className="flex justify-between w-full px-4 py-5 sm:p-6  font-roboto"
                >
                  <span className="flex text-17 font-semibold text-black">
                    {item.question}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className={`w-6 h-6 text-gray-400 transform transition-transform duration-200 ${openQuestion === index ? "rotate-0" : "rotate-180"}`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {openQuestion === index && (
                  <div className="px-4 pb-5 sm:px-6 sm:pb-6 transition-opacity duration-300 ease-in-out">
                    <p className={`opacity-100 text-15  font-roboto text-left transition-opacity duration-300 ${openQuestion === index ? 'opacity-100' : 'opacity-0'}`}>
                      {item.answer}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Business;