import React from 'react'
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Functionality } from '../context/Functionality.js'
import { useMediaQuery } from 'react-responsive';

export default function Navbar() {
    const { auth, handleChangeAuth, handleAuthSubmit, token, logout } = useContext(Functionality)

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });

    const handleLogout = () => {
        logout();
        setUserMenuOpen(false)
    }

    return (
        <nav className="bg-gradient-to-b from-custom-blue-dark to-custom-blue-darker" >
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden toggle-menu">
                        {/* Mobile menu button */}
                        <button
                            type="button"
                            className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:from-custom-blue-dark "
                            aria-controls="mobile-menu"
                            aria-expanded={mobileMenuOpen}
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg
                                className={`${mobileMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                            <svg
                                className={`${mobileMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex  items-center justify-center sm:items-stretch sm:justify-start">
                        <div className=" cursor-pointer">
                            <Link to="/">
                                <img className="h-16 w-auto rounded-full" src="/Logowhite.png" alt="Your Company" />
                            </Link>
                        </div>
                        <div className="hidden sm:ml-6 sm:block pt-3">
                            <div className="flex space-x-4">
                                <Link to="/" className=" px-3 py-2 text-15 font-medium text-white hover:text-[#7da5f7]" aria-current="page">
                                    Home
                                </Link>
                                <Link to="/clients" className=" px-3 py-2 text-15 font-medium text-white  hover:text-[#7da5f7] ">
                                    Business
                                </Link>
                                <Link to="/ios" className=" px-3 py-2 text-15 font-medium text-white  hover:text-[#7da5f7] ">
                                    iOS Bootcamp
                                </Link>
                                <Link to="/financing" className=" px-3 py-2 text-15 font-medium text-white  hover:text-[#7da5f7] ">
                                    Financing
                                </Link>
                                <Link to="/contact" className=" px-3 py-2 text-15 font-medium text-white  hover:text-[#7da5f7] ">
                                    Contact
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center  sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        {/* Notifications button */}
                        <button
                            type="button"
                            className="relative rounded-full bg-transparent p-1 text-white  "
                        >
                            <span className="sr-only">View notifications</span>
                            <svg className="h-6 w-6 hover:opacity-75 hover:text-[#7da5f7]" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                            </svg>
                        </button>

                        {/* Profile dropdown */}
                        <div className="relative ml-3">
                            {token ?
                                <div>
                                    <button
                                        type="button"
                                        className="relative flex rounded-full bg-gray-800 text-15 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                                        id="user-menu-button"
                                        aria-expanded={userMenuOpen}
                                        aria-haspopup="true"
                                        onClick={() => setUserMenuOpen(!userMenuOpen)}
                                    >
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            className="h-8 w-8 rounded-full hover:opacity-75"
                                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                            alt=""
                                        />
                                    </button>
                                </div>
                                :
                                !isMobile &&
                                <Link to="/login" className=" px-3 py-2 text-15 font-medium text-white hover:text-[#7da5f7]" aria-current="page">
                                    Login
                                </Link>

                            }
                            {/* Dropdown menu */}
                            {userMenuOpen && (
                                <div
                                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="user-menu-button"
                                >
                                    <Link to="/student-list" className="block px-4 py-2 text-15 text-gray-700" role="menuitem">
                                        Employee Portal
                                    </Link>
                                    <Link to="#" onClick={handleLogout} className="block px-4 py-2 text-15 text-gray-700" role="menuitem">
                                        Sign out
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            {
                mobileMenuOpen && (
                    <div className="sm:hidden" id="mobile-menu">
                        <div className="space-y-1 px-2 pb-3 pt-2 ">

                            <Link to="/" className=" px-3 py-2 text-15 font-medium text-white block hover:text-[#7da5f7]" aria-current="page" onClick={() => setMobileMenuOpen(!mobileMenuOpen)} >
                                Home
                            </Link>
                            <Link to="/clients" className=" px-3 py-2 text-15 font-medium text-white  hover:text-[#7da5f7] block " onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                Business
                            </Link>
                            <Link to="/ios" className=" px-3 py-2 text-15 font-medium text-white  hover:text-[#7da5f7]  block" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                iOS Course
                            </Link>
                            <Link to="/financing" className=" px-3 py-2 text-15 font-medium text-white  hover:text-[#7da5f7]  block" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                Financing
                            </Link>
                            <Link to="/contact" className=" px-3 py-2 text-15 font-medium text-white  hover:text-[#7da5f7] block" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                Contact
                            </Link>
                            <Link to={`${token ? '#' : '/Login'}`} className=" px-3 py-2 text-15 font-medium text-white hover:text-[#7da5f7] block" aria-current="page" onClick={token ? () => {
                                setMobileMenuOpen(!mobileMenuOpen)
                                logout()
                            } :
                                () => { setMobileMenuOpen(!mobileMenuOpen) }
                            }>
                                {token ? 'Logout' : 'Login'}
                            </Link>
                        </div>
                    </div>
                )
            }
        </nav >
    )
}
