import React, { useState, useEffect, useContext } from 'react'

import BusinessCard from './EmployeeBusinessCard'
import { Functionality } from '../context/Functionality.js'

const BusinessListTable = () => {

    const { getAllBusinesses, businesses } = useContext(Functionality)

    useEffect(() => {
        getAllBusinesses()
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentDetail, setCurrentDetail] = useState(null);

    const toggleModal = (business) => {
        setIsModalOpen(!isModalOpen);
        setCurrentDetail(business);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 8;

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    // Handle the case where business or business.business might be undefined
    const currentBusiness = (businesses?.businesses || []).slice(indexOfFirstRow, indexOfLastRow);

    // Calculate pagination info
    const totalBusiness = businesses?.businesses?.length || 0;
    const showingFrom = totalBusiness === 0 ? 0 : indexOfFirstRow + 1;
    const showingTo = Math.min(indexOfLastRow, totalBusiness);

    return (
        <div className="flex flex-col mt-8">
            <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
                    <div className="overflow-x-auto">
                        <table className="min-w-full border">
                            <thead>
                                <tr className='bg-blue-600 text-white'>
                                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left  uppercase border-b  left-0 z-10">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left  uppercase border-b sm:table-cell">
                                        Email
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left  uppercase border-b sm:table-cell">
                                        Phone Number
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left  uppercase border-b md:table-cell">
                                        Date
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left  uppercase border-b md:table-cell">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-xs font-medium tracking-wider text-left  uppercase border-b md:table-cell">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {
                                    currentBusiness?.map((business, index) => (
                                        <tr key={index}>
                                            <td  data-label="Name" className="px-6 py-4 whitespace-no-wrap border-b border-gray-200  left-0 bg-white z-10">
                                                <div className="flex items-center">
                                                    <div className="text-sm font-medium leading-5 text-gray-900">
                                                        {business?.data?.name}
                                                    </div>
                                                </div>
                                            </td>
                                            <td  data-label="Email" className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 sm:table-cell">
                                                <div className="text-sm font-medium leading-5 text-gray-900">{business?.data?.email}</div>
                                            </td>
                                            <td  data-label="Phone" className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 sm:table-cell">
                                                <div className="text-sm font-medium leading-5 text-gray-900">{business?.data?.phoneNumber}</div>
                                            </td>
                                            <td  data-label="Date" className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 sm:table-cell">
                                                <div className="text-sm font-medium leading-5 text-gray-900">{business?.data?.date}</div>
                                            </td>
                                            <td   data-label="Un-Contacted" className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 sm:table-cell">
                                                <span className={`inline-flex px-2 p-1 text-xs font-semibold leading-5 rounded-full ${business?.uncontacted ? "text-gray-800 bg-gray-100"
                                                    : business?.underContract ? "text-blue-800 bg-blue-100"
                                                        : business?.processing ? "text-yellow-800 bg-yellow-100"
                                                            : business?.declined ? "text-red-800 bg-red-100"
                                                                : ""}`}>
                                                    {business?.uncontacted ? 'Un-Contacted' : business?.underContract ? 'Under Contract' : business?.processing ? 'Processing' : business?.declined ? 'Declined' : 'N/A'}
                                                </span>
                                            </td>
                                            <td  data-label="Action" className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 sm:table-cell">
                                                <button
                                                    className="text-indigo-600 hover:text-indigo-900"
                                                    type="button"
                                                    onClick={() => toggleModal(business)}
                                                >
                                                    Edit
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </table>
                        {currentBusiness.length === 0 &&
                            <div className="flex flex-col items-center justify-center py-8">
                                <svg
                                    className="w-12 h-12 text-gray-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 13h6m2 6H7a2 2 0 01-2-2V7a2 2 0 012-2h10a2 2 0 012 2v10a2 2 0 01-2 2z"
                                    />
                                </svg>
                                <p className="mt-4 text-lg font-medium text-gray-600">
                                    No Data Available
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                    There are currently no businesses to display.
                                </p>
                            </div>
                        }
                    </div>

                    {/* Pagination Info */}
                    {currentBusiness.length !== 0 &&
                        <div className="flex justify-between items-center p-2">
                            <p className="text-sm text-gray-700">
                                {`Showing ${showingFrom} to ${showingTo} of ${totalBusiness} results`}
                            </p>

                            {/* Pagination Controls */}
                            <div className="flex">
                                <button
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                    className="bg-gray-200 text-gray-800 px-4 py-2 rounded-lg mx-2"
                                >
                                    Prev
                                </button>
                                <button
                                    onClick={() => setCurrentPage(prev => (indexOfLastRow < totalBusiness ? prev + 1 : prev))}
                                    disabled={indexOfLastRow >= totalBusiness}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    }
                    {/* Main modal */}
                    {isModalOpen && (
                        <div
                            tabIndex="-1"
                            aria-hidden="true"
                            className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-800 bg-opacity-50"
                            onClick={toggleModal}
                        >
                            <div className="relative p-4 w-full max-w-2xl max-h-full">
                                {/* Modal content */}

                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4" onClick={(e) => e.stopPropagation()}>
                                    {/* Header */}
                                    <div className="flex items-center justify-betweenrounded-t dark:border-gray-600 mb-2">
                                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                            Business Details
                                        </h3>
                                        <button onClick={toggleModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                            </svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                    {/* Content */}
                                    <BusinessCard business={currentDetail} toggleModal={toggleModal} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>

    );
};

export default BusinessListTable;
