import React, { useEffect, useContext } from 'react'
import { Functionality } from '../context/Functionality.js'
import BusinessCount from './BusinessCards';
import BusinessListTable from './BusinessTable.js';


export default function EmployeeBusinessList(props) {

    const { getAllBusinesses, businesses } = useContext(Functionality)

    useEffect(() => {
        getAllBusinesses()
    }, [])

    const unContactedNum = (businesses?.businesses?.filter(business => business.uncontacted === true) || []).length;
    const processingNum = (businesses?.businesses?.filter(business => business.processing === true) || []).length;
    const underContractNum = (businesses?.businesses?.filter(business => business.accepted === true) || []).length;
    const declinedNum = (businesses?.businesses?.filter(business => business.declined === true) || []).length;


    return (
        <div>
            <BusinessCount unContactedNum={unContactedNum} processingNum={processingNum} underContractNum={underContractNum} declinedNum={declinedNum} />
            <div className="businessList-businessCard mt-6">
                <BusinessListTable />
            </div>
        </div>
    )
}
