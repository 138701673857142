import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="w-full h-screen flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-y-0 lg:space-x-8 p-4 lg:p-0">
            {/* Text Section */}
            <div className="w-full lg:w-1/2 flex flex-col items-center justify-center text-center">
                <h1 className="text-7xl md:text-8xl lg:text-9xl font-bold text-gray-300">404</h1>
                <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-300 mt-2">Page Not Found</h2>
                <p className="text-lg md:text-xl lg:text-2xl text-gray-500 mt-4 mb-8">
                    Sorry, the page you are looking for could not be found.
                </p>
                <Link
                    to="/"
                    className="flex items-center space-x-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded transition duration-150 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
                    title="Return Home"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            fillRule="evenodd"
                            d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <span>Return Home</span>
                </Link>
            </div>

            {/* Image Section */}
            <div className="w-full lg:w-1/2 flex justify-center">
                <img
                    src="https://user-images.githubusercontent.com/43953425/166269493-acd08ccb-4df3-4474-95c7-ad1034d3c070.svg"
                    alt="404 - Page not found"
                    className="object-contain max-w-full h-auto"
                    loading="lazy"
                />
            </div>
        </div>
    );
};

export default NotFound;
