import React, {useState, useContext, useEffect} from 'react'

export const Text = React.createContext()

export default function ContextProvider(props){
   
    // ----- About Us -----
    const aboutUs = "We Build Apps for Small Businesses"

    const whatWeOffer = "Whether you are a business owner looking to expand your horizons with a state-of-the-art app, or a future student looking to start a lucrative career in tech, Blake Technologies is the key to get you there. We boast building apps for small businesses as well as offering software courses for future students that are taught by actual experienced professionals in the industry. See our Contact form below to reach out."

    // ----- Contact Us -----
    const contactUs = "Are you a small business owner and would like us to build an app for your business for FREE? - OR - Are you interested in becoming a software engineer to make it big in the exploding tech industry? Please select the option below that applies to you and fill out the corresponding contact form."

    // ----- Courses -----
    const courses = "Blake Technologies prides itself on it's well thought out and professionally developed courses. Our staff is qualified and eager to provide the best structure, support, and experience that a coding bootcamp has to offer. If you put your faith in us, we will put our faith in you as you develop REAL projects for REAL clients during your time with Blake Technologies. It's time to invest in your future."

    const ios = "Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."

    const web = "Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat."

    const testimonials = [ 
    <div className="home-testimonials-fromText">
    "I have full confidence in anything Blake does. When I was struggling with employment in the tech world he helped me make adjustments that landed me a coding job in less than 3 months."
    <br />
    -McKay Burnett | <span className="home-testimonials-career">Full-Stack Web Developer</span>
    </div>,
    <div className="home-testimonials-fromText">
    "Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus."
    <br />
    -Some Dude | <span className="home-testimonials-career">Software Engineer</span>
    </div>,
    <div className="home-testimonials-fromText">
    "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    <br />
    -Another Guy | <span className="home-testimonials-career">App Developer</span>
    </div>
    ]

    const description = `Learn from experienced iOS Engineers and build Apps like a pro`
    const businessDescription = `Get an App for your business without breaking the bank`
    const objectives = `Variables,
    Constants,
    Operators,
    Data Types,
    Proper naming
    conventions,
    Conditionals,
    Control Flow,
    Functions,
    Parameters,
    Results,
    MVC,
    Design Patterns, 
    MVVM,
    Communication,
    ProtocolandDelegate, Loops,
    Class,
    Struct,
    Optionals,
    UIViewController, AppleHIG,
    GitHub,
    VersionControl, UITableView,
    Singleton,
    Segues,
    LocalPersistence, JSON,
    UIAlertController,
    Notification and Listener,
    JSONSerialization, Postman,
    API Documentation, URLSession,
    Closure,
    Concurrency,
    GCD,
    Codable,
    Enum,
    Memory Management, ARC,
    Dictionaries,
    Arrays,
    Extensions,
    Patterns,
    Result Type, 
    Capture Lists,
    URL Requests,
    Abstraction && Single
    Responsibility,
    Async and Await,
    Pagination,
    Google Firebase,
    Google Firestore,
    HTTP Methods,
    App Planning,
    Story mapping,
    AGILE Methodologies, Interface Builder,
    Introduction to BigO Notation,
    Introduction to Data structures and
    Algorithms,
    Objective - C,
    Core Data,
    iOS Interview process, Introduction to UI/Unit
    testing. `
    const arrObjectives = objectives.split(",")
    const mappedArr = arrObjectives.map((item, index) => (
        <div key={index} className="ios-obj-item">
          <h4>{item}</h4>
        </div>
      ));
    const iosSubTitle = `Upon successful completion of the iOS Programming Course, you will have a confindent command and understanding of the following ...`

    const daily = 
        <div className="ios-daily">
            <h1>Monday - Thursday</h1>
            <h3><span>9:00am - 9:30am: </span>Coffee Chat <br /> Connect with your teammates and the team lead to discuss the previous day</h3>
            <h3><span>9:30am - 10:30am: </span>Formal Lecture <br /> Using a slide deck we will go over in-depth topics</h3>
            <h3><span>10:30am - 11:00am: </span>Break</h3>
            <h3><span>11:00am - 1:00pm: </span>Live Coding Lecture <br /> Interactive lecture where you code along with your instructor</h3>
            <h3><span>1:00pm - 2:00pm: </span>Lunch <br /> I'm thnking Chipotle</h3>
            <h3><span>2:00pm - 3:00pm: </span>Pair Programming - Support Closed <br /> Paired Programming without instructor support. It's imoportant to learn how to learn on your own and use external  resources.</h3>
            <h3><span>3:00pm - 5:00pm: </span>Pair Programming - Support Open <br /> Instructors are live and on-call to support you. We will answer any questions you may have</h3>
            <h3><span>5:00pm - 7:00pm: </span>Review Pre-Class Materials <br /> Prepare for the next day by getting an introduction to what we will learn the following day</h3>
            <h1 id="friday">Friday</h1>
            <h3 id="optional"><span>9:00am - 7:00pm: </span>One-on-ones and Assessment <br /> Get personal time coding with Blake and assess where you're at in you're learning process.</h3>
        </div>

    return(
        <Text.Provider
            value={{
                aboutUs,
                whatWeOffer,
                contactUs,
                courses,
                ios,
                web,
                testimonials,
                businessDescription,
                description,
                objectives,
                mappedArr,
                iosSubTitle,
                daily
            }}
        >
            {props.children}
        </Text.Provider>
    )
}