import React from 'react';
import { FaQuestion } from "react-icons/fa6";
import { GiRolledCloth } from "react-icons/gi";
import { SiProcessingfoundation } from "react-icons/si";
import { RiMacLine } from "react-icons/ri";

const BusinessCard = (props) => {

    const { unContactedNum, processingNum, underContractNum, declinedNum } = props;

    return (
        <>

            <div className='overflow-x-hidden'>
                <h3 className="text-2xl font-medium text-gray-700 pl-1 pt-16 md:pt-1">Business List</h3>
                <div className="mt-4">
                    <div className="flex flex-wrap mt-3 sm:gap-y-2 ">
                        {/* Card 1 */}
                        <div className="w-full sm:w-1/2 xl:w-1/4 ">
                            <div className="flex items-center px-5 py-6 bg-fuchsia-50 rounded-md shadow-sm">
                                <div className="p-3 bg-indigo-600 bg-opacity-75 rounded-full text-center">
                                    <FaQuestion fill='currentColor' style={{ fontSize: '25px', color: 'white' }} />
                                </div>
                                <div className="mx-5">
                                    <h4 className="text-2xl font-semibold text-gray-700">{unContactedNum}</h4>
                                    <div className="text-indigo-600">Un-contacted</div>
                                </div>
                            </div>
                        </div>

                        {/* Card 2 */}
                        <div className="w-full sm:px-2  sm:w-1/2 xl:w-1/4 mt-6 sm:mt-0 ">
                            <div className="flex items-center px-5 py-6 bg-violet-100 rounded-md shadow-sm">
                                <div className="p-3 bg-orange-600 bg-opacity-75 rounded-full">
                                    <SiProcessingfoundation style={{ fontSize: '25px', color: 'white' }} />
                                </div>
                                <div className="mx-5">
                                    <h4 className="text-2xl font-semibold text-gray-700">{processingNum}</h4>
                                    <div className="text-orange-600">Processing</div>
                                </div>
                            </div>
                        </div>

                        {/* Card 3 */}
                        <div className="w-full  sm:w-1/2 xl:w-1/4 mt-6 md:mt-0">
                            <div className="flex items-center px-5 py-6 bg-indigo-100 rounded-md shadow-sm">
                                <div className="p-3 bg-pink-600 bg-opacity-75 rounded-full">
                                    <GiRolledCloth style={{ fontSize: '25px', color: 'white' }} />
                                </div>
                                <div className="mx-5">
                                    <h4 className="text-2xl font-semibold text-gray-700">{underContractNum}</h4>
                                    <div className="text-pink-600">Under-contract</div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full sm:px-2 sm:w-1/2 xl:w-1/4 mt-6 md:mt-0">
                            <div className="flex items-center px-5 py-6 bg-fuchsia-50 rounded-md shadow-sm">
                                <div className="p-3 bg-green-600 bg-opacity-75 rounded-full">
                                    <RiMacLine style={{ fontSize: '25px', color: 'white' }} />
                                </div>
                                <div className="mx-5">
                                    <h4 className="text-2xl font-semibold text-gray-700">{declinedNum}</h4>
                                    <div className="text-green-600">Declined</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default BusinessCard;
