import React from 'react'

export default function SectionFour() {
    return (
        <section
            className='bg-[#f4f8fc]'
        >

            <div className="lg:py-24 py-10">
                <div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 lg:max-w-7xl lg:px-8">
                    <h1 className='block text-[#232f4b] text-24 lg:text-50 leading-36 lg:leading-60 font-roboto  font-bold text-center pb-5 '>Application  <span className="text-blue-600">Development</span>  <br className='lg:block hidden' /> Stages</h1>
                    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
                        <div className="group relative">
                            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-60">
                                <img src="/images/blog/1.jpg" alt="Front of men&#039;s Basic Tee in black." className="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                            </div>
                    <div className=' h-auto lg:h-3/6 p-5 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]'>
                    <h2 className="text-20  pl-2  pt-4 font-semibold tracking-tight text-gray-900 font-roboto" > Discovery & Planning(Analysis)</h2 >
                    <p className="text-15 font-normal text-gray-900 pl-2 pt-4 font-roboto" > We collaborate with businesses to understand their vision and requirements.We assess their goals, target audience, and the technical aspects necessary to build a successful app that drives growth.</p >
                    </div>
                        </div >
                        <div className="group relative" >
                            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-60" >
                                <img src="/images/blog/2.jpg" alt="Front of men&#039;s Basic Tee in black." className="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                            </div >
                            <div className=' h-auto lg:h-3/6 p-5 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]'>
                            <h2 className="text-20  pl-2  pt-4 font-semibold tracking-tight text-gray-900 font-roboto" > UI / UX Design</h2 >
                            <p className="text-15 font-normal text-gray-900 pl-2 pt-4 font-roboto" > Our design team works with the business to create intuitive, user - friendly interfaces that align with their brand and industry standards.We focus on aesthetics, ease of use, and functionality to enhance user engagement.</p >
                            </div >
                        </div >
                        <div className="group relative" >
                            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-60" >
                                <img src="/images/blog/3.jpg" alt="Front of men&#039;s Basic Tee in black." className="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                            </div >
                            <div className=' h-auto lg:h-3/6 p-5 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]'>
                            <h2 className="text-20  pl-2  pt-4 font-semibold tracking-tight text-gray-900 font-roboto" > Development</h2 >
                            <p className="text-15 font-normal text-gray-900 pl-2 pt-4 font-roboto" > Supervised by our senior developers, our skilled student teams develop robust, scalable applications using modern technologies.Every line of code is crafted to ensure performance, security, and flexibility.</p >
                        </div >
                        </div>
                        <div className="group relative" >
                            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-60" >
                                <img src="/images/blog/4.jpg" alt="Front of men&#039;s Basic Tee in black." className="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                            </div >
                            <div className=' h-auto lg:h-3/6 p-5 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]'>
                            <h2 className="text-20  pl-2  pt-4 font-semibold tracking-tight text-gray-900 font-roboto" > Testing & QA</h2 >
                            <p className="text-15 font-normal text-gray-900 pl-2 pt-4 font-roboto" > We rigorously test the app across different devices, operating systems, and screen resolutions to ensure seamless functionality.Bug fixes and performance optimizations are done to ensure the highest quality.
                            </p >
                            </div>
                        </div >
                        <div className="group relative" >
                            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-60" >
                                <img src="/images/blog/5.jpg" alt="Front of men&#039;s Basic Tee in black." className="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                            </div >
                            <div className=' h-auto lg:h-3/6 p-5 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]'>
                            <h2 className="text-20  pl-2  pt-4 font-semibold tracking-tight text-gray-900 font-roboto" > Launch</h2 >
                            <p className="text-15 font-normal text-gray-900 pl-2 pt-4 font-roboto" > We handle the full deployment process, including submission to the Apple App Store and Google Play Store, ensuring your app meets all platform guidelines for a smooth launch.
                            </p >
                            </div>
                        </div >
                        <div className="group relative" >
                            <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-60" >
                                <img src="/images/blog/6.jpg" alt="Front of men&#039;s Basic Tee in black." className="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                            </div >
                            <div className=' h-auto lg:h-3/6 p-5 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]'>
                            <h2 className="text-20  pl-2  pt-4 font-semibold tracking-tight text-gray-900 font-roboto" > Ongoing Support & Maintenance</h2 >
                            <p className="text-15 font-normal text-gray-900 pl-2 pt-4 font-roboto" > After launch, we provide continuous monitoring, updates for new iOS and Android versions, and tech support to ensure long - term stability and performance of your app.
                            </p >
                            </div>
                        </div >
                    </div >

                </div >
            </div >
        </section >
    )
}
