import React, { useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Business from './components/Business.js';
import Financing from './components/Financing.js';
import Navbar from './components/Navbar.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import EmployeePortal from './components/EmployeePortal.js';
import StudentList from './components/EmployeeStudentList.js';
import BusinessList from './components/EmployeeBusinessList.js';
import BlogPost from './components/EmployeeBlog.js';
import Home from './components/Home.js';
import Ios from './components/IOS';
import Loan from './components/finance/Loan.js';
import Contact from './components/frontend/ContactForm';
import BottomNav from './components/BottomNav.js';
import { Functionality } from './context/Functionality.js';
import Login from './components/auth/login.js';
import Register from './components/auth/register.js';
import AddBlog from './components/AddBlog.js';
import EmployeePost from './components/EmployeePost.js';
import NotFound from './components/NotFound'; // Create this component for 404
import Services from './components/Services.js';

function App() {
  const { token } = useContext(Functionality);
  const location = useLocation();

  // Check if the current path is either login or register
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';
  const innerPages = ['/student-list', '/blog-post', '/business-list', '/add-blog'];
  const isInnerPage = innerPages.includes(location.pathname);

  return (
    <div className="app-wrapper">
      {!isInnerPage && <Navbar />}
      {/* {token && <EmployeePortal />} */}
      <Routes>
        <Route
          path="/employeePortal"
          element={
            <ProtectedRoute token={token} redirectTo="/">
              <EmployeePortal />
            </ProtectedRoute>
          }
        />
        <Route
          path="/student-list"
          element={
            <ProtectedRoute token={token} redirectTo="/">
              <EmployeePortal>
                <StudentList />
              </EmployeePortal>
            </ProtectedRoute>
          }
        />
        <Route
          path="/business-list"
          element={
            <ProtectedRoute token={token} redirectTo="/">
              <EmployeePortal>
                <BusinessList />
              </EmployeePortal>
            </ProtectedRoute>
          }
        />
        <Route
          path="/blog-post"
          element={
            <ProtectedRoute token={token} redirectTo="/">
              <EmployeePortal>
                <BlogPost />
              </EmployeePortal>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-blog"
          element={
            <ProtectedRoute token={token} redirectTo="/">
              <EmployeePortal>
                <AddBlog />
              </EmployeePortal>
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Home />} />
        <Route path="/employee-post/:blogId" element={<EmployeePost />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ios" element={<Ios />} />
        <Route path="/clients" element={<Business />} />
        <Route path="/loan" element={<Loan />} />
        <Route path="/financing" element={<Financing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/service/:id" element={<Services />} />
        {/* Catch-all route for 404 - Page Not Found */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!isAuthPage && !isInnerPage && <BottomNav />}
    </div>
  );
}

export default App;
