import React from 'react'
import { Link } from 'react-router-dom'

export default function BottomNav() {

    return (
        <footer className="bg-gradient-to-b from-custom-blue-dark to-custom-blue-darker">
            <div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 lg:max-w-7xl lg:px-8 p-0 md:p-8 xl:px-0">
                <div className="mx-auto md:px-6 pb-10 pt-7">
                    <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                        <div className="space-y-4 sm:flex sm:justify-items-start sm: xl:block xxl:block border-b border-gray-400/30 xl:border-0 xxl:border-0 pb-7">
                            <div classNameName='flex justify-start '>
                                <Link to="/">
                                    <div className="flex justify-center md:justify-start">
                                        <span>
                                            <img width="100" height="100" className="w-100" src="/Logowhite.png" alt="Your Company" />
                                        </span>
                                    </div>

                                </Link>
                            </div>
                            <div className=" pl-0 lg:pr-16 text-md text-gray-200">Enhance productivity and
                                efficiency with cutting-edge artificial intelligence solutions for your business operations.
                            </div>
                        </div>
                        <div className="mt-16  grid md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-2 gap-8 xl:col-span-2 xl:mt-0 ">
                            <div className="">
                                <div>
                                    <h3 className="text-md font-semibold leading-6 font-roboto text-white">Our Solutions</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        <li>
                                            <Link to="#"
                                                className="text-md leading-6 text-gray-300 font-roboto font-normal hover:text-gray-50">Affordable App Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#"
                                                className="text-md leading-6 text-gray-300 font-roboto font-normal hover:text-gray-50">Custom Mobile Solutions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#"
                                                className="text-md leading-6 text-gray-300 font-roboto font-normal hover:text-gray-50">Expert-Led Development Teams
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="">
                                <div className=" sm:mt-0">
                                    <h3 className="text-md font-semibold leading-6 font-roboto text-white">Company</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        <li>
                                            <Link to="/ios"
                                                className="text-md leading-6 text-gray-300 font-roboto font-normal hover:text-gray-50">About Blake Technologies
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/clients"
                                                className="text-md leading-6 text-gray-300 font-roboto font-normal hover:text-gray-50">Join as a Business Partner
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/financing"
                                                className="text-md leading-6 text-gray-300 font-roboto font-normal hover:text-gray-50">Student Program
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact"
                                                className="text-md leading-6 text-gray-300 font-roboto font-normal hover:text-gray-50">Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className=" md:mt-0">
                                    <h3 className="text-md font-semibold leading-6 font-roboto text-white">Use Cases</h3>
                                    <ul role="list" className="mt-6 space-y-4">
                                        <li>
                                            <Link to="#"
                                                className="text-md leading-6  text-gray-300 hover:text-gray-50">Small Business Solutions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#"
                                                className="text-md leading-6 text-gray-300 font-roboto font-normal hover:text-gray-50">Cost-Effective Mobile Apps
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#"
                                                className="text-md leading-6 text-gray-300 font-roboto font-normal hover:text-gray-50">Portfolio Projects for Students
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                    <div className="mt-16 border-t border-gray-400/30 pt-8 sm:mt-20 lg:mt-24">
                        <div className="text-md text-center font-roboto text-white">
                            Copyright © 2024 . Blake Technologies
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}