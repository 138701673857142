import React, { useEffect, useState, useContext } from 'react'
import { Functionality } from '../context/Functionality.js'
import AddBlog from './AddBlog.js';
import BlogTable from './BlogTable.js';
import EmployeePost from './EmployeePost.js'
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom'

export default function EmployeeBlog() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' });
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { getAllBlogs } = useContext(Functionality)

  useEffect(() => {
    getAllBlogs();
  }, [])

  return (
    <>
      <div className='flex justify-between align-center border-b pb-3 md:mt-0 mt-16'>
        <h3 className="text-2xl font-medium text-gray-700 ">Blog Posts</h3>
        <button
          type="button"
          className="block h-9 rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out bg-[#0e2234] "
          onClick={() => {
            isMobile ? navigate('/add-blog') : toggleModal();
          }}
        >
          Add Blog
        </button>
      </div>
      <div className='mt-4'>
        <BlogTable />
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-50">
          <div className="relative w-full max-w-lg sm:max-w-2xl lg:max-w-4xl p-6 pb-1 ml-4 mr-4 mx-auto bg-white rounded-xl shadow-2xl">
            {/* Header */}
            <div className="flex items-center justify-betweenrounded-t dark:border-gray-600 mb-2">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Blog Details
              </h3>
              <button onClick={toggleModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <AddBlog toggleModal={toggleModal} selectedBlog={null} />
          </div>
        </div>
      )}
    </>
  )
}