import React, { useState, useEffect, useContext } from 'react'
import { Functionality } from '../context/Functionality'

export default function EmployeeBusinessCard(props) {

    const { business, toggleModal } = props
    const { declined, underContract, processing, uncontacted, note, data, _id } = business
    const { name, email, phoneNumber, date, message } = data
    const { handleDeleteBusiness, editBusiness } = useContext(Functionality)


    const initRadio = {
        uncontacted: false,
        processing: false,
        underContract: false,
        declined: false,
    };

    const [radio, setRadio] = useState(initRadio);
    const [localInputs, setLocalInputs] = useState({
        note: note || ""
    });

    const handleChangeRadio = (e) => {
        const { name } = e.target;
        setRadio({
            ...initRadio,  // Reset all to false
            [name]: true,  // Set the selected one to true
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLocalInputs((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    function handleSubmit(e) {
        e.preventDefault()
        const updatedInputs = { ...localInputs, note: localInputs.note };
        editBusiness(_id, updatedInputs)
    }

    function handleTransfer() {
        editBusiness(_id, radio)
        toggleModal();
    }

    function handleRemove() {
        handleDeleteBusiness(_id);
        toggleModal();
    }

    return (
        <>
            <div className={` bg-white max-w-2xl shadow overflow-hidden sm:rounded-lg mb-6 ${uncontacted ? "" : processing ? "bg-blue-100" : underContract ? "bg-green-100" : declined && "bg-red-100"}`}>
                <div className="px-4 py-3 sm:px-4 bg-[#0e2234]">
                    <h3 className="text-lg leading-6 font-bold text-white">{name}</h3>
                    <p className="mt-1 text-sm text-white">{email}</p>
                </div>

                <div className="border-t border-gray-200">
                    <dl>

                        <div className="grid grid-cols-2 grid-flow-col gap-4">
                            <div className="bg-gray-50 px-4 py-3 flex justify-between md:grid sm:grid-cols-2 sm:gap-4 sm:px-4">
                                <dt className="text-sm font-bold text-[#0e2234]">Phone</dt>
                                <dd className="md:mt-1 text-end text-sm text-black sm:mt-0 mailto:sm:col-span-2">{phoneNumber || 'N/A'}</dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 flex justify-between md:grid sm:grid-cols-2 sm:gap-4 sm:px-4 border-l">
                                <dt className="text-sm font-bold text-[#0e2234]">Date</dt>
                                <dd className="md:mt-1 text-sm text-black sm:mt-0 mailto:sm:col-span-2 text-end">{date || 'N/A'}</dd>
                            </div>
                        </div>

                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 ">
                            <dt className="text-sm font-bold text-[#0e2234]">Message</dt>
                            <dd className="mt-1 text-sm text-black sm:mt-0 sm:col-span-2 h-16 overflow-auto">{message || 'N/A'}</dd>
                        </div>
                    </dl>
                </div>

                <div className="px-4 py-3">
                    <form onSubmit={handleSubmit}>
                        <dt className="text-sm font-bold text-black">Note</dt>
                        <textarea
                            className="w-full border border-gray-300 rounded-md mt-1 p-2 mb-4"
                            id="note"
                            name="note"
                            value={localInputs.note}
                            onChange={handleInputChange}
                            placeholder="Add a note..."
                        ></textarea>
                        <button type="submit" className="bg-[#0e2234]   hover:bg-opacity-75 text-white py-2 px-4 rounded-md float-end">
                            Add Note
                        </button>
                    </form>
                </div>

                <div className="px-4 py-5 mt-5">
                    <h4 className="text-lg font-medium mb-3 border-t">Status:</h4>
                    <div className="flex flex-wrap gap-3 pt-3">
                        {['declined', 'processing', 'uncontacted', 'underContract'].map((status) => (
                            <div key={status} className="mb-4 flex">
                                <label className="inline-flex items-center cursor-pointer">
                                    <input
                                        type="radio"
                                        name={status}
                                        value={status}
                                        checked={radio[status]}
                                        onChange={handleChangeRadio}
                                        className="form-radio h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 text-gray-700 font-medium capitalize">{status}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="px-4 py-3 md:flex block   justify-end md:space-x-2">
                    <button
                        className="bg-[#0e2234] hover:bg-opacity-75 text-white py-2 w-full px-4 rounded-md md:mb-0 mb-4"
                        onClick={handleTransfer}
                    >
                        Transfer Business
                    </button>
                    <button
                        className="bg-red-500 hover:bg-red-400 text-white py-2 px-4 w-full rounded-md"
                        onClick={handleRemove}
                    >
                        Remove Business
                    </button>
                </div>
            </div>
        </>

    )
}