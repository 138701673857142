import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

export default function Services() {
    const location = useLocation();
    const service = location.state?.service; // Optional chaining to avoid errors

    useEffect(() => {
        if (!service) {
            console.warn('No service data available.');
        }
    }, [service]);

    if (!service) {
        return <div>No service data available.</div>; // Fallback UI if no service data is present
    }

    return (
        <>
            <div class="relative font-sans before:absolute before:w-full before:h-full before:inset-0 before:bg-black before:opacity-50 before:z-10">
                <img src="https://readymadeui.com/cardImg.webp" alt="Banner Image" class="absolute inset-0 w-full h-full object-cover" />
                <div class="min-h-[350px] relative z-50 h-full max-w-6xl mx-auto flex flex-col justify-center items-center text-center text-white p-6">
                    <h2 class="text-center text-white text-3xl font-bold font-manrope leading-normal">{service.title}</h2>
                    <p class="max-w-4xl mx-auto text-center text-white text-lg font-normal leading-8">{service.description}</p>
                </div>
            </div>
            <section class="lg:py-12 py-7 relative">
                <h2 className='text-center text-3xl font-bold font-manrope leading-normal lg:pb-16 pb-7'>Technologies</h2>
                <div class="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
                    <div class="w-full flex-col justify-start items-center lg:gap-11 gap-8 inline-flex">
                        <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 justify-start items-start gap-8">
                            {service.technologies.map((tech, index) => (
                                <div key={index} class="w-full px-6 py-8 h-auto lg:h-60 rounded-2xl border border-gray-200 hover:border-indigo-600 transition-all duration-700 ease-in-out justify-center items-center gap-2.5 flex">
                                    <div class="flex-col justify-start items-center gap-3.5 inline-flex">
                                        <img src={tech.icon} alt={tech.title} style={{ width: '40px', height: '40px' }} />
                                        <h4 class="text-center text-gray-900 text-lg font-semibold leading-8">{tech.title}</h4>
                                        <p class="text-center text-gray-500 text-sm font-normal leading-snug">{tech.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
