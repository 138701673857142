import React from 'react';
import Climb from '../../src/outside/climbLogo.png';
import Blake from '../../src/outside/Logo.png';
import { useMediaQuery } from 'react-responsive';
import { FaPlus } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Financing = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
    <>

      <section id="home" class="xl:pt-16 xl:pb-16 py-8 bg-no-repeat bg-cover" style={{
        backgroundImage: isMobile ? 'none' : 'url(https://img.freepik.com/free-photo/people-technology-concept-smiling-asian-girl-using-smartphone-texting-mobile-phone-standing_1258-92568.jpg?t=st=1728380322~exp=1728383922~hmac=072012cc677a39054ff42d17ed1e95614407d1b8ef8794619672a508c844d1dc&w=1380)',
        backgroundSize: 'cover', // This ensures the image covers the entire section
        backgroundPositionY: 'center', // Center the image
      }}>
        <div class="px-4  mx-auto sm:max-full md:max-w-full lg:max-w-screen-xl md:px-24 xl:px-8 lg:py-20">
          <div class="lg:grid block  lg:grid-cols-1 xl:grid-cols-2 grid-cols-1 gap-x-6 gap-y-10 items-center">
            <div>
              <h1 class=" text-24 lg:text-50 leading-36 lg:leading-60 font-roboto font-bold mt-7">Invest in Your <span className='text-blue-500'>Future</span> with <span className='text-blue-500'>Confidence</span></h1>
              <p class="text-17 font-normal text-gray-500 mt-5">To empower our students to invest in themselves, Blake Technologies has partnered with Climb Credit to provide the best opportunities available.</p>

              <p class="text-15 font-normal text-gray-500 flex font-roboto  items-center gap-3 mt-6"> Take charge of your financial journey by making informed decisions today.</p>

              <p class="text-15 font-normal text-gray-500 flex  font-roboto  items-center gap-3 mt-4"> Approach your financial goals with confidence.</p>

              <p class="text-15 font-normal text-gray-500 flex font-roboto  items-center gap-3 mt-4"> Build a secure and prosperous future for yourself and your loved ones.</p>


              <div class="mt-8">
                <Link className=""
                  target={'_blank'} to="https://climbcredit.com/applyto/blaketechnologies?page=create-account&schoolId=BL6513252959957" >
                  <button className="relative w-full md:w-40 block md:inline-flex mt-6 items-center justify-center p-0.5 lg:mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                    <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                      APPLY NOW
                    </span>
                  </button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/* <section>
        <div className="mb-3 lg:mb-16">
          <div className="relative px-4 sm:px-0 lg:pt-14 pt-5">

            <div className="relative grid mx-auto overflow-hidden  gap-5   sm:max-w-screen-sm md:grid-cols-3 lg:grid-cols-3 lg:max-w-screen-md">

              <div className="inline-block p-8 text-center bg-sky-100 hover:bg-sky-200 rounded-sm">
                <div className="flex items-center justify-center w-36 h-auto mx-auto mb-4 rounded-full bg-indigo-50 ">
                  <img src={Climb} alt="Climb logo" className="rounded-full" />
                </div>
              </div>

              <div className="inline-block p-8 text-center bg-sky-100 hover:bg-sky-200 rounded-sm md:block hidden">
                <div className="flex items-center justify-center mx-auto w-36 h-full">
                  <FaPlus className="w-12 h-12 text-indigo-300 lg:w-12 lg:h-12 dark:text-indigo-300 hover:text-indigo-300" />
                </div>
              </div>

              <div className="inline-block p-8 text-center bg-sky-100 hover:bg-sky-200 rounded-sm">
                <div className="flex items-center justify-center w-36 h-auto mx-auto mb-4 rounded-full bg-indigo-50  border-gray-300 ">
                  <img src={Blake} alt="Blake logo" className="rounded-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section >
        <section className="bg-white dark:bg-gray-900 lg:py-24"
          style={{
            backgroundImage: 'url(https://bioneerslive.org/wp-content/uploads/2024/09/custom-software-banner.jpg)',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
            backgroundSize: "cover",
          }}
        >
          <div className=" w-full xl:max-w-screen-xl px-4 sm:px-5 md:px-24 xl:px-8 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h2 className=" w-full xl:max-w-3xl font-roboto mb-4 font-bold text-white text-24 lg:text-50 leading-36 lg:leading-60">
                Apply now and get your Climb financing offer in just 5 minutes without any impact on your credit!
              </h2>
              <p className="max-w-2xl mb-6 font-roboto  text-15 font-light text-white lg:mb-8 md:text-20 lg:text-20 dark:text-gray-400">
                To get started, simply <strong className='text-white font-medium'>provide your basic information,</strong> including your name, email, and phone number. Next, enter your <strong className='text-white font-medium'>address</strong> and <strong className='text-white font-medium'>Social Security number.</strong> You’ll then <strong className='text-white font-medium'>choose your course type</strong> —whether you prefer a Self-Paced or Full-Time option—and select your desired loan term. Finally, just review and sign the disclosure to receive your financing offer instantly. It's that simple!
              </p>
              <div className='text-left'>
                <Link className=""
                  target={'_blank'} to="https://climbcredit.com/applyto/blaketechnologies?page=create-account&schoolId=BL6513252959957" >
                  <button className="relative w-full md:w-40 block md:inline-flex mt-6 items-center justify-center p-0.5 lg:mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
                    <span className="relative w-full block md:inline  px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                      APPLY NOW
                    </span>
                  </button>
                </Link>
              </div>
            </div>
            {/* <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
              <img src={Credit} alt="Credit mockup" className="transition-transform duration-300 ease-in-out transform hover:scale-105" />
            </div> */}
          </div>
        </section>
      </section >

    </>
  );
};

export default Financing;
