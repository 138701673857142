import React, {useState, useEffect, useContext} from 'react'
import {BrowserRouter as Routes, Route, Link} from 'react-router-dom'
import ClimbLogo from '../../outside/climbLogo.png'
import Logo from '../../outside/Logo.png'

export default function Loan(){

    return(
        <div className="loan-wrapper">
            <div className="column1">
                <h1 className="loan-title2">To better help our students invest in themselves, Blake Technologies has partenered with <span>Climb Credit </span>to give you the best opportunities possible.</h1>
                <div className="column2">
                    <img src={ClimbLogo} className="loan-climbLogo" />
                    <h1 className="loan-plus">+</h1>
                    <img src={Logo} className="loan-logo" />
                </div>
                <h1 className="loan-title2">Apply and receive a Climb financing offer in just 5 minutes with no harm to your credit.</h1>
                <h1 className="loan-title3">1. Enter basic info like name, email and phone</h1>
                <h1 className="loan-title3">2. Enter info like address and social security #</h1>
                <h1 className="loan-title3">3. Select between Self Pace and Full Time Course and the loan term</h1>
                <h1 className="loan-title3">4. Sign the disclosure and then receive your offer! It's that easy</h1>
                <Link to="https://climbcredit.com/applyto/blaketechnologies?page=create-account&schoolId=BL6513252959957" style={{textDecoration: "none"}}>
                    <button className="home-subject-button" id="loan-button"style={{marginTop: "20px", marginBottom: "20px"}}><span style={{letterSpacing: ".5em", fontWeight: "bold"}}>Apply</span></button>
                </Link>
            </div>
            
        </div>
        
    )
}