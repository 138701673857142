import { FaAngellist } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { BsFillFileEarmarkPostFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
    const { toggleSidebar } = props;

    return (
        <div>
            <div
            >
                <div className=" text-black">
                    <div className='p-2'>
                        <div className="text-1xl font-bold text-center ">
                            <Link to={'/'}>
                                <img width="100" height="100" className="w-100 mx-auto" src="/Logowhite.png" alt="Your Company" />
                            </Link>
                        </div>
                        <div className="block md:hidden absolute top-5 right-5">
                            <MdClose style={{ fontSize: '25px', color: 'white', cursor: 'pointer' }} onClick={toggleSidebar} />
                        </div>
                    </div>
                    <div className="h-full px-3 py-4 overflow-y-auto">
                        <ul className="space-y-2 font-medium">
                            <li>
                                <Link onClick={toggleSidebar} to="/student-list" className="flex items-center p-2 text-gray-900  rounded-lg dark:text-white hover:bg-gray-900 dark:hover:bg-gray-700 group">
                                    <FaAngellist style={{ fontSize: '25px', color: 'white' }} />
                                    <span className="ms-3 text-white ">Student List</span>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={toggleSidebar} to="/business-list" className="flex items-center p-2 text-gray-900  rounded-lg dark:text-white hover:bg-gray-900 dark:hover:bg-gray-700 group">
                                    <MdBusinessCenter style={{ fontSize: '25px', color: 'white' }} />
                                    <span className="ms-3 text-white ">Business List</span>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={toggleSidebar} to="/blog-post" className="flex items-center p-2 text-gray-900  rounded-lg dark:text-white hover:bg-gray-900 dark:hover:bg-gray-700 group">
                                    <BsFillFileEarmarkPostFill style={{ fontSize: '25px', color: 'white' }} />
                                    <span className="ms-3 text-white ">Blog Post</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
